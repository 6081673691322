<template>
  <section>
    <land-img
      :height="wdHeight"
      :src="cover"
      flat
      max-width="100%"
      tile
    >
      <v-container
        style="max-width: 1200px; height: 90%;"
      >
        <v-row
          class="ma-0 fill-height"
          align="center"
        >
          <v-col
            cols="12"
            md="7"
            sm="12"
          >
            <div class="co-flex-col ml-4">
              <span class="text-h6 text-md-h4 white--text ">{{ header.title }}</span>
              <span
                v-if="header.english"
                class="co-text-sm white--text mt-4"
              >{{ header.english }}</span>
              <div class="co-w32 co-h4 co-bg-white mt-6" />
              <div
                class="co-w32"
                :class="[smAndDown ? 'co-h24' : 'co-h40']"
              />
              <span
                v-if="header.subtitle"
                class="white--text"
                :class="[
                  smAndDown ? 'co-text-sm' : 'co-text-md',
                ]"
              >{{ header.subtitle }}</span>
              <v-btn
                v-if="button.text"
                depressed
                :color="button.backColor || 'white'"
                :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text', 'mt-8']"
                width="120"
              >
                {{ button.text }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            class="hidden-sm-and-down"
          />
        </v-row>
      </v-container>
    </land-img>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import data from '@/data/co.data'

  export default {
    name: 'SectionGuideEnvironment',
    props: {},
    data () {
      return {
        divisible: true,
        item: {},
        cover: data.comm.guideEnv.cover,
        classify: {},
        header: data.comm.guideEnv.header,
        button: {
          text: '查看详情'
        },
        points: {
          xs: 300,
          sm: 400,
          md: 500,
          lg: 500,
          xl: 500
        },
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || 500
        return ht
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 120
        } else {
          return 140
        }
      },
    },
    created () {
    },
    methods: {
      getData () {
        const me = this
        let params = null
        const executed = function (res) {
          console.log('params: %o', params)
          if (res.status) {
            const item = params.items[0] || {}
            const cnt = item.content || {}
            me.item = item
            me.classify = cnt.classify || {}
            me.header = cnt.header || {}
            me.button = cnt.button || {}
            me.cover = item.cover || ''
          }
        }
        params = web.banner.getParams({
          type: web.comm.BannerTypes.HOME_ACTIVITY,
          currentPage: '1',
          pageSize: '1',
          executed
        })

        api.httpx.getItems(params)
      }
    }
  }
</script>
